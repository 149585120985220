import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { UserModel } from '../../shared/models/user.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  public set token(token: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('token', token);
    }
  }

  public get token(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('token') || '';
    }
    return '';
  }

  public set userData(data: UserModel) {
    localStorage.setItem('userData', JSON.stringify(data));
  }

  public get userData(): UserModel {
    return JSON.parse(localStorage.getItem('userData') || '');
  }

  public removeToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
  }
}
